'use strict';
(function(){

class MoveTurbineModalCtrl {
  constructor($scope, $uibModalInstance, $state, Site, Turbine, options, usSpinnerService, Util, ActivityLog, Auth) {
    this.Util = Util;
    this.Site = Site;
    this.Turbine = Turbine;
    this.usSpinnerService = usSpinnerService;
    this.$state = $state;
    this.$uibModalInstance = $uibModalInstance;
    this.isDisabled = false;
    this.error = {};
    this.options = options;
    this.submitted = false;
    this.ActivityLog = ActivityLog;
    Auth.isLoggedInAsync((loggedIn) => {
      if (loggedIn) {
        this.currentUser = Auth.getCurrentUser();
      }
    });

    this.turbine = this.options.turbine;
    console.log(this.turbine);
    this.Site.get({ sortByField: 'name' }).$promise
    .then(response=>{
    	this.sites = response.data;
    	_.remove(this.sites, {_id: this.turbine.site._id });
    });
    this.newSite = null;
    $scope.$on('$stateChangeStart', function() {
      $uibModalInstance.dismiss();
    });
  }

  moveTurbine(form) {
    this.submitted = true;
    this.error = null;
    if (form.$valid) {
      this.isDisabled = true;
      this.startSpin('spinner-1');
      this.Turbine.moveToSite({ id: this.turbine._id }, { oldSiteId: this.turbine.site._id, newSiteId: this.newSite._id }).$promise
        .then(turbine=> {
          this.stopSpin('spinner-1');
          console.log(turbine);
          let turbineStr = '';
          if(this.turbine.make) {
            turbineStr+=this.turbine.make
          }
          if(this.turbine.model) {
            turbineStr+=this.turbine.model
          }
          if(this.turbine.name) {
            turbineStr+=this.turbine.name
          }
          const activityLogData = {
            site: this.newSite._id,
            turbine: turbine.data._id,
            user: this.currentUser._id,
            source: 'web',
            action: 'move',
            type: 'turbine',
            sourceScreen: 'turbine',
            eventString: `Turbine <a href="/turbine/${turbine.data._id}">${turbineStr}</a> was moved from <a href="/site/${this.turbine.site._id}">${this.turbine.site.name}</a> to <a href="/site/${this.newSite._id}">${this.newSite.name}</a> by ${this.currentUser.name}`
          }

          this.ActivityLog.save(activityLogData).$promise
            .then(activityLog => {
              console.log(activityLog);
            })
            .catch(err => {
              console.log('Error inserting activityLog', err);
          });
          this.$uibModalInstance.close(turbine);
          // this.$state.go('main');
          this.$state.reload();
        })
        .catch(err=> {
          this.isDisabled = false;
          this.stopSpin('spinner-1');
          this.error = err;
        });
    }
  }

  cancelMove() {
    this.$uibModalInstance.dismiss('cancel');
  }

  startSpin(spinner) {
    this.usSpinnerService.spin(spinner);
  }

  stopSpin(spinner) {
    this.usSpinnerService.stop(spinner);
  }
}

angular.module('windmanagerApp')
  .controller('MoveTurbineModalCtrl', MoveTurbineModalCtrl);

})();
